import {AjaxFormComponent} from "ui-base/src/Components/Form/_resources/ts/AjaxFormComponent";
import {ComponentLoader} from "../../../../../../AbstractComponent";

declare let $: any;

export class SignupFormAjax extends AjaxFormComponent
{
    public static selector: string = 'signup-form-ajax-component';

    init()
    {
        super.init();

        this
          .addAjaxOption('crossDomain', true)
          .addAjaxOption('xhrFields', {
              withCredentials: true
          });

        this.getComponentElement().prepend(
            $("<div>").attr(SignupFormAjax.selector + "-error-holder", true)
        );
    }

    protected onResponseFail(data)
    {
        console.log("failed response: ", data);
    }

    protected onResponseSuccess(data)
    {
        if (typeof data.errors !== "undefined" && data.errors.length > 0)
        {
            let errors = "<ul class='list list--style-none'>";

            for (let i = 0; i < data.errors.length; i++) {
                errors += "<li>" + data.errors[i] + "</li>";
            }

            errors += "</ul>";

            this.getComponentElement().find(`[${SignupFormAjax.selector}-error-holder]`).html('').prepend(
                `<div class="notification notification--negative">
                  <div class="notification__message">
                    <p>${errors}</p>
                  </div>
                </div>
               <br>
            `);

            this.getComponentElement().find(':input').removeAttr('readonly');
            this.setButtonToProcessing(false);

            return this;
        }
        else
        {
            if (window.location.hostname.includes('.cubex-local.com'))
            {
                console.log('cookie should be set');
            }
            else
            {
                const reg = /(?:pr-[\d]*.)?www./;
                window.location.href = window.location.origin.replace(reg, 'secure.');
            }
        }
    }
}

new ComponentLoader(SignupFormAjax);
